<template>
  <v-dialog v-model="dialog" width="500">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        outlined
        color="primary"
        v-bind="attrs"
        v-on="on"
        class="mr-2 btn-fix action-btn"
      >
        {{button_name}}
        <v-icon right dark>mdi-video-outline</v-icon>
      </v-btn>
    </template>

    <v-card>
      <v-card-title class="text-h5">
        {{ title }}
      </v-card-title>

      <v-card-text>
        <div style="position: relative; padding-top: 56.25%;">
          <iframe
            v-if="dialog"
            :src="
              'https://iframe.videodelivery.net/' + videoURI + '?autoplay=true'
            "
            style="border: none; position: absolute; top: 0; left: 0; height: 100%; width: 100%;"
            allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;"
            allowfullscreen="true"
          ></iframe>
        </div>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="dialog = false">
          Close
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style scoped>
.btn-fix:focus::before {
  opacity: 0 !important;
}

@media only screen and (max-width: 600px) {
  .action-btn {
    margin-top: 2px;
    margin-bottom: 2px;
    width: 100%;
  }
}
</style>

<script>
export default {
  props: {
    title: {
      type: String,
      default: "Instructions",
    },
    videoURI: {
      type: String,
      default: "fd30b2a2a06f6eed7597910557e676ad",
    },
    button_name: {
      type: String,
      default: "Instructions"
    },
  },
  data() {
    return {
      dialog: false,
    };
  },
};
</script>
