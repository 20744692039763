var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"elevation-3 ma-2",attrs:{"headers":_vm.headers,"items":_vm.agent_data_filtered,"sort-by":"name","loading":_vm.loading,"search":_vm.search,"single-expand":true,"expanded":_vm.expanded,"item-key":_vm.index,"items-per-page":-1,"show-expand":""},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Agents")]),(_vm.filter_menu)?_c('v-spacer'):_vm._e(),_c('v-btn-toggle',{attrs:{"color":"primary","group":"","mandatory":""},model:{value:(_vm.grouper),callback:function ($$v) {_vm.grouper=$$v},expression:"grouper"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"value":"all"}},'v-btn',attrs,false),on),[_vm._v(" Everyone ")])]}}])},[_c('span',[_vm._v("Everyone, regardless of status.")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"value":"registered"}},'v-btn',attrs,false),on),[_vm._v(" Registered ")])]}}])},[_c('span',[_vm._v("Registered accounts who are not; customers, agents or internal.")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"value":"customers"}},'v-btn',attrs,false),on),[_vm._v(" Customers ")])]}}])},[_c('span',[_vm._v("People who have bought stuff, but are not agents.")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"value":"agents"}},'v-btn',attrs,false),on),[_vm._v(" Agents ")])]}}])},[_c('span',[_vm._v("People who are currently licensed with AnnuityCheck.")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"value":"internal"}},'v-btn',attrs,false),on),[_vm._v(" Internal ")])]}}])},[_c('span',[_vm._v("Internal Users")])])],1),_c('vue-json-to-csv',{attrs:{"json-data":_vm.agent_data_filtered.map(function (a) {
            return {
              Name: a.first_name + ' ' + a.last_name,
              Email: a.email,
              Phone: a.phone,
              Street: a.street,
              City: a.city,
              State: a.state,
              Zip: a.zip,
              Created: a.created,
              Approved: a.approved ? 'Yes' : 'No',
              'Annuity Premium': a.annuity_premium,
              Carriers: a.allowed_carriers_aggregate.aggregate.count,
              Campaigns: a.campaigns_aggregate.aggregate.count,
              'Seminar Control': a.seminar_control_training ? 'Yes' : 'No',
              'Software Expiration': a.software_expiry,
              Internal: a.hidden ? 'Yes' : 'No',
              Source: a.source,
            };
          })}},[_c('v-btn',{staticClass:"mx-4",attrs:{"color":"primary"}},[_vm._v(" Download CSV ")])],1),(_vm.filter_menu)?_c('v-text-field',{staticClass:"ml-2",attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","clearable":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}):_vm._e()],1)]},proxy:true},{key:"item.seminar_control_training",fn:function(ref){
          var item = ref.item;
return [(item.seminar_control_training)?_c('v-icon',{attrs:{"color":"success"}},[_vm._v(" mdi-check ")]):_c('v-icon',{attrs:{"color":"error"}},[_vm._v(" mdi-close ")])]}},{key:"item.approved",fn:function(ref){
          var item = ref.item;
return [(item.approved)?_c('v-icon',{attrs:{"color":"success"}},[_vm._v(" mdi-check ")]):_c('v-icon',{attrs:{"color":"error"}},[_vm._v(" mdi-close ")])]}},{key:"item.created",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.created))+" ")]}},{key:"item.source",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatSource(item.source))+" ")]}},{key:"item.oto_purchase_date",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.oto_purchase_date))+" ")]}},{key:"item.software_expiry",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(item.software_expiry == "Unlimited" ? "Unlimited" : _vm.formatDate(item.software_expiry))+" ")]}},{key:"item.phone",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatPhoneNumber(item.phone))+" ")]}},{key:"expanded-item",fn:function(ref){
          var headers = ref.headers;
          var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('user-card',{attrs:{"user_id":item.id}})],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }