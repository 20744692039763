<template>
  <v-card>
    <v-card-text v-if="allCarriers.length > 0">
      <v-simple-table dense :loading="loading">
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">
                Carrier
              </th>
              <th class="text-left">
                Approve
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="c in allCarriers" :key="c.id">
              <td>{{ c.name }}</td>
              <td>
                <v-switch
                  v-model="c.approved"
                  hide-details
                  dense
                  :loading="c.loading"
                  :disabled="c.loading"
                  class="ma-0 px-0 py-1"
                  @change="changeState(c)"
                ></v-switch>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card-text>
    <v-card-text v-else>
      <!-- loading -->
      <v-progress-linear
        indeterminate
        color="primary"
        class="mt-0"
      ></v-progress-linear>
    </v-card-text>
  </v-card>
</template>

<script>
import hasuraService from "@/services/hasura.service.js";

export default {
  name: "UserCardCarriers",
  props: {
    user_id: {
      type: Number,
      required: true,
    },
  },
  mixins: [hasuraService],
  watch: {
    user_id: function() {
      this.setup();
    },
  },
  mounted() {
    this.setup();
  },
  methods: {
    async changeState(carrier) {
      carrier.loading = true;
      try {
        await this.setApprovedCarrier(
          this.user_id,
          carrier.id,
          carrier.approved
        );
        carrier.loading = false;
      } catch (e) {
        this.$store.commit(
          "set_snackbar",
          "Server Error, please contact support or refresh page and try again"
        );
        carrier.loading = false;
      }
    },
    async setup() {
      this.loading = true;
      // get approved carriers
      const approvedCarriers = (
        await this.getApprovedUserCarriers(this.user_id)
      ).map((c) => c.carrier_id);

      // get all carriers
      const allCarriers = (await this.getAllCarriers()).sort((a, b) => {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return 0;
      });

      allCarriers.forEach((carrier) => {
        carrier.approved = approvedCarriers.includes(carrier.id);
        carrier.loading = false;
      });

      this.allCarriers = allCarriers;

      this.loading = false;
    },
  },
  data() {
    return {
      loading: false,
      allCarriers: [],
    };
  },
};
</script>
