<template>
  <v-data-table
    :headers="headers"
    :items="clients"
    :loading="loading"
    hide-default-footer
    disable-pagination
    @click:row="handleClick"
    sort-by="created_at"
    sort-desc
    class="elevation-1 my-3"
  >
    <template v-slot:top>
      <v-toolbar flat>
        <v-toolbar-title>Cases</v-toolbar-title>
        <v-spacer></v-spacer>

        <instructions-dialog
          title="How to Set Up a New Case"
          videoURI="e962c53f93e453105d60efdd5cdb9a73"
        ></instructions-dialog>
        <v-dialog v-model="dialog" max-width="600px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" dark v-bind="attrs" v-on="on">
              <v-icon left>mdi-plus</v-icon>
              New Case
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="text-h5">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-form ref="form" v-model="valid">
                  <v-row>
                    <v-col cols="12" sm="6">
                      <v-text-field
                        v-model="editedItem.name"
                        label="Client Name"
                        :rules="req"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-radio-group
                        v-model="editedItem.gender"
                        :row="!$vuetify.breakpoint.xsOnly"
                      >
                        <template v-slot:label>
                          <span>Gender</span>
                        </template>
                        <v-radio
                          dense
                          v-for="g in [
                            { view: 'Male', value: 'male' },
                            { view: 'Female', value: 'female' },
                          ]"
                          :key="g.value"
                          :label="g.view"
                          :value="g.value"
                        ></v-radio>
                      </v-radio-group>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-select
                        v-model="editedItem.state"
                        :items="all_states"
                        label="State"
                        item-value="abbreviation"
                        item-text="name"
                        :rules="req"
                      ></v-select>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-text-field
                        v-model="editedItem.age"
                        validate-on-blur
                        label="Current Age"
                        type="number"
                        :rules="[
                          (v) => !!v || 'Field is required',
                          (v) =>
                            (v && v >= 40) || 'Age must be greater than 40',
                          (v) => (v && v < 99) || 'Age must be less than 99',
                        ]"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-form>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="close"> Cancel </v-btn>
              <v-btn color="primary" @click="save" :loading="loading">
                <v-icon left>mdi-check</v-icon>
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="text-h5">
              Are you sure you want to delete this item?
            </v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete">
                Cancel
              </v-btn>
              <v-btn color="blue darken-1" text @click="deleteItemConfirm">
                OK
              </v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    <!-- eslint-disable-next-line -->
    <template v-slot:item.gender="{ item }">
      <span class="titlecase">{{ item.gender }}</span>
    </template>
    <template v-slot:item.created_at="{ item }">
      <span>{{ dateFormat(item.created_at) }}</span>
    </template>
    <!-- eslint-disable-next-line -->
    <template v-slot:item.actions="{ item }">
      <v-icon small class="mr-2" @click.stop="editItem(item)">
        mdi-pencil
      </v-icon>
      <v-icon small @click.stop="deleteItem(item)"> mdi-delete </v-icon>
    </template>
  </v-data-table>
</template>

<style scoped>
.titlecase {
  text-transform: capitalize;
}
::v-deep tr {
  cursor: pointer !important;
}
</style>

<script>
import hasuraService from "@/services/hasura.service.js";
import InstructionsDialog from "@/components/Seminar/InstructionsDialog.vue";
import dayjs from "dayjs";

export default {
  mixins: [hasuraService],
  props: {
    user_id: Number,
  },
  components: {
    InstructionsDialog,
  },

  data: () => ({
    valid: true,
    req: [(v) => !!v || "Field is required"],
    dialog: false,
    dialogDelete: false,
    loading: false,
    headers: [
      { text: "Name", value: "name" },
      { text: "Gender", value: "gender" },
      { text: "State", value: "state" },
      { text: "Age", value: "age" },
      { text: "Current Annuities", value: "current_annuities" },
      { text: "Calculations", value: "calculations" },
      { text: "Created", value: "created_at" },
      { text: "Actions", value: "actions", sortable: false },
    ],
    clients: [],
    editedIndex: -1,
    editedItem: {
      id: null,
      user_id: null,
      name: "",
      gender: "male",
      state: "",
      age: null,
    },
    defaultItem: {
      id: null,
      user_id: null,
      name: "",
      gender: "male",
      state: "",
      age: null,
    },
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Case" : "Edit Case";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  created() {
    this.initialize();
  },

  methods: {
    dateFormat(date) {
      return dayjs(date).format("MMM DD YYYY");
    },
    async update_le(age, gender) {
      age = +age;
      if (age > 40) {
        let result = await this.getLE(gender, age);
        if (result.ok) {
          return result.life_exp;
        }
      }
      return 95;
    },
    handleClick(e) {
      this.$router.push(`/client/${e.id}`);
    },
    async initialize() {
      // TODO: read from database
      this.loading = true;
      this.clients = (await this.client_by_user_id(this.user_id)).map(
        (client) => {
          return {
            ...client,
            created_at: client.created_at,
            current_annuities: client.client_current_annuities.length,
            calculations: client.calculators.length,
          };
        }
      );
      this.loading = false;
    },

    editItem(item) {
      this.editedIndex = this.clients.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.clients.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      // delete actually happens here
      this.delete_client_by_pk(this.editedItem.id).then(() => {
        this.$store.commit("set_snackbar", "Client deleted");
      });
      this.clients.splice(this.editedIndex, 1);
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    async save() {
      if (this.$refs.form.validate()) {
        this.editedItem.life_exp = await this.update_le(
          this.editedItem.age,
          this.editedItem.gender
        );
        if (this.editedIndex > -1) {
          // update actually happens here
          await this.update_clients_by_pk(this.editedItem.id, this.editedItem);
          this.$store.commit("set_snackbar", "Client updated");
          Object.assign(this.clients[this.editedIndex], this.editedItem);
          this.dialog = false;
        } else {
          // create actually happens here
          this.loading = true;
          this.editedItem.user_id = this.user_id;
          this.clients.push(this.editedItem);
          this.insert_client(this.editedItem).then((id) => {
            // route to client by id
            this.$router.push(`/client/${id}`);
          });
        }
      }
    },
  },
};
</script>
