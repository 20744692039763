<template>
  <v-data-table
    :headers="headers"
    :items="agent_data_filtered"
    sort-by="name"
    class="elevation-3 ma-2"
    :loading="loading"
    :search="search"
    :single-expand="true"
    :expanded.sync="expanded"
    :item-key="index"
    :items-per-page="-1"
    show-expand
  >
    <template v-slot:top>
      <v-toolbar flat>
        <v-toolbar-title>Agents</v-toolbar-title>
        <v-spacer v-if="filter_menu"></v-spacer>

        <v-btn-toggle v-model="grouper" color="primary" group mandatory>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn value="all" v-bind="attrs" v-on="on"> Everyone </v-btn>
            </template>
            <span>Everyone, regardless of status.</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn value="registered" v-bind="attrs" v-on="on">
                Registered
              </v-btn>
            </template>
            <span
              >Registered accounts who are not; customers, agents or
              internal.</span
            >
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn value="customers" v-bind="attrs" v-on="on">
                Customers
              </v-btn>
            </template>
            <span>People who have bought stuff, but are not agents.</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn value="agents" v-bind="attrs" v-on="on"> Agents </v-btn>
            </template>
            <span>People who are currently licensed with AnnuityCheck.</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn value="internal" v-bind="attrs" v-on="on">
                Internal
              </v-btn>
            </template>
            <span>Internal Users</span>
          </v-tooltip>
        </v-btn-toggle>

        <!-- <v-switch v-if="filter_menu" class="mr-2 mt-5" v-model="approved" label="Agents"></v-switch>
        <v-switch v-if="filter_menu" class="mr-2 mt-5" v-model="sem_control" label="Seminar Control"></v-switch>
        <v-switch v-if="filter_menu" class="mr-2 mt-5" v-model="hidden" label="Internal"></v-switch> -->

        <vue-json-to-csv
          :json-data="
            agent_data_filtered.map((a) => {
              return {
                Name: a.first_name + ' ' + a.last_name,
                Email: a.email,
                Phone: a.phone,
                Street: a.street,
                City: a.city,
                State: a.state,
                Zip: a.zip,
                Created: a.created,
                Approved: a.approved ? 'Yes' : 'No',
                'Annuity Premium': a.annuity_premium,
                Carriers: a.allowed_carriers_aggregate.aggregate.count,
                Campaigns: a.campaigns_aggregate.aggregate.count,
                'Seminar Control': a.seminar_control_training ? 'Yes' : 'No',
                'Software Expiration': a.software_expiry,
                Internal: a.hidden ? 'Yes' : 'No',
                Source: a.source,
              };
            })
          "
        >
          <v-btn color="primary" class="mx-4"> Download CSV </v-btn>
        </vue-json-to-csv>

        <v-text-field
          v-if="filter_menu"
          class="ml-2"
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          clearable
          hide-details
        ></v-text-field>
      </v-toolbar>
    </template>
    <!-- eslint-disable-next-line -->
    <template v-slot:item.seminar_control_training="{ item }">
      <v-icon v-if="item.seminar_control_training" color="success">
        mdi-check
      </v-icon>
      <v-icon v-else color="error"> mdi-close </v-icon>
    </template>
    <!-- eslint-disable-next-line -->
    <template v-slot:item.approved="{ item }">
      <v-icon v-if="item.approved" color="success"> mdi-check </v-icon>
      <v-icon v-else color="error"> mdi-close </v-icon>
    </template>
    <!-- eslint-disable-next-line -->
    <template v-slot:item.created="{ item }">
      {{ formatDate(item.created) }}
    </template>
    <!-- eslint-disable-next-line -->
    <template v-slot:item.source="{ item }">
      {{ formatSource(item.source) }}
    </template>
    <!-- eslint-disable-next-line -->
    <template v-slot:item.oto_purchase_date="{ item }">
      {{ formatDate(item.oto_purchase_date) }}
    </template>
    <!-- eslint-disable-next-line -->
    <template v-slot:item.software_expiry="{ item }">
      {{
        item.software_expiry == "Unlimited"
          ? "Unlimited"
          : formatDate(item.software_expiry)
      }}
    </template>
    <!-- eslint-disable-next-line -->
    <template v-slot:item.phone="{ item }">
      {{ formatPhoneNumber(item.phone) }}
    </template>
    <template v-slot:expanded-item="{ headers, item }">
      <td :colspan="headers.length">
        <user-card :user_id="item.id"></user-card>
      </td>
    </template>
  </v-data-table>
</template>

<style scoped>
.v-text-field {
  max-width: 400px;
}
</style>

<script>
import UserCard from "../../components/Admin/UserCard.vue";
import dayjs from "dayjs";
import VueJsonToCsv from "vue-json-to-csv";

export default {
  name: "AgentTable",
  components: { UserCard, VueJsonToCsv },
  props: {
    index: {
      type: String,
      default: "id",
    },
    agent_data: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
    filter_menu: {
      type: Boolean,
      default: true,
    },
  },

  data: () => ({
    grouper: "agents",
    expanded: [],
    search: "",
    sem_control: false,
    approved: false,
    hidden: false,
    headers: [
      {
        text: "Name",
        value: "name",
      },
      { text: "Phone", value: "phone" },
      { text: "Sem. Cntrl", value: "seminar_control_training" },
      { text: "Software Exp.", value: "software_expiry" },
      { text: "OTO Purc. Dt.", value: "oto_purchase_date" },
      { text: "Source", value: "source" },
      { text: "Agent", value: "approved" },
      { text: "Campaigns", value: "campaign_cache" },
      { text: "Marketer", value: "marketer_clean" },
    ],
  }),
  computed: {
    clean_agent_data() {
      return this.agent_data.map((a) => {
        return {
          ...a,
          software_expiry: a.approved ? "Unlimited" : a.software_expiry,
          oto_purchase_date: a.purchases.length
            ? a.purchases[0].created_at
            : "",
        };
      });
    },
    agent_data_filtered: function () {
      return this.clean_agent_data.filter((agent) => {
        if (this.grouper === "all") {
          return true;
        }
        // registered
        if (this.grouper === "registered") {
          return (
            !agent.approved &&
            !agent.hidden &&
            !agent.seminar_control_training &&
            !agent.has_ppt &&
            agent.campaign_cache == 0
          );
        }
        if (this.grouper === "customers") {
          return (
            (agent.seminar_control_training ||
              agent.has_ppt ||
              agent.campaign_cache > 0) &&
            !agent.hidden &&
            !agent.approved
          );
        }
        if (this.grouper === "agents") {
          return agent.approved && !agent.hidden;
        }
        if (this.grouper === "internal") {
          return agent.hidden;
        }
      });
    },
  },

  methods: {
    formatSource(source) {
      return {
        unknown: "Unknown",
        arn: "ARN",
        linkedin: "LinkedIn",
        email: "Email",
        eca: "ECA",
        lead_marvels: "Lead Marvels",
        other: "Other",
      }[source];
    },
    formatPhoneNumber(phoneNumberString) {
      if (!phoneNumberString) {
        return "";
      }
      var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
      var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
      if (match) {
        return "(" + match[1] + ") " + match[2] + "-" + match[3];
      }
      return phoneNumberString;
    },
    formatDate(date) {
      if (!date) {
        return "";
      }
      return dayjs(date).format("MM/DD/YYYY");
    },
    handleClick: function (e) {
      this.$router.push(`/admin/agents/${e.id}`);
    },
  },
};
</script>
