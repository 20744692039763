<template>
  <v-card elevation="4" max-width="800" class="mt-4 mb-4 mx-auto">
    <v-tabs v-model="tab" show-arrows>
      <v-tabs-slider></v-tabs-slider>

      <v-tab v-for="(item, index) in tab_items" :key="index">
        {{ item }}
      </v-tab>
    </v-tabs>

    <v-card-text>
      <v-card v-if="tab == 0" elevation="1">
        <v-card-title>Agent Details</v-card-title>
        <v-card-text>
          <v-form v-model="valid" ref="form">
            <v-row>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="user.id"
                  label="User ID"
                  dense
                  :loading="loading"
                  :disabled="true"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="user.first_name"
                  label="First Name"
                  dense
                  :loading="loading"
                  :disabled="loading"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="user.last_name"
                  label="Last Name"
                  dense
                  :loading="loading"
                  :disabled="loading"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="user.email"
                  label="Email"
                  dense
                  :loading="loading"
                  :disabled="loading"
                >
                </v-text-field>
              </v-col>

              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="user.phone"
                  label="Phone"
                  type="number"
                  dense
                  :loading="loading"
                  :disabled="loading"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="user.street"
                  label="Street"
                  dense
                  :loading="loading"
                  :disabled="loading"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="user.city"
                  label="City"
                  dense
                  :loading="loading"
                  :disabled="loading"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-select
                  v-model="user.state"
                  :items="all_states"
                  label="State"
                  item-value="abbreviation"
                  item-text="name"
                  dense
                  :loading="loading"
                  :disabled="loading"
                ></v-select>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="user.zip"
                  label="Zip"
                  dense
                  :loading="loading"
                  :disabled="loading"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-select
                  v-model="user.marketer_id"
                  :items="marketers"
                  label="Marketer"
                  item-value="id"
                  dense
                  :loading="loading"
                  :disabled="loading"
                ></v-select>
              </v-col>
              <v-col cols="12" sm="6">
                <v-select
                  v-model="user.production_level"
                  :items="[
                    { value: '0-1.9', text: '0 - 1.9 Million' },
                    { value: '2-4.9', text: '2 - 4.9 Million' },
                    { value: '5-9.9', text: '5 - 9.9 Million' },
                    { value: '10-14.9', text: '10 - 14.9 Million' },
                    { value: '15+', text: '15+ Million' },
                  ]"
                  label="Production Level (last 12 months)"
                  dense
                  :loading="loading"
                  :disabled="loading"
                ></v-select>
                <!-- <v-select
                  v-model="user.annuity_premium"
                  :items="[
                    'Less than $5,000,000 Premium',
                    'Between $5,000,000 and $10,000,000 Premium',
                    'Over $10,000,000 Premium',
                  ]"
                  label="Annuity Premium Written: Last 12 months"
                  dense
                  :loading="loading"
                  :disabled="loading"
                ></v-select> -->
              </v-col>

              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="user.current_imos"
                  label="Current IMOs"
                  dense
                  :loading="loading"
                  :disabled="loading"
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="6">
                <v-select
                  v-model="user.status"
                  :items="[
                    'Registered',
                    'Active',
                    'Inactive',
                    'Not Interested',
                  ]"
                  label="Status"
                  dense
                  :loading="loading"
                  :disabled="loading"
                ></v-select>
              </v-col>

              <v-col cols="12" sm="6">
                <v-text-field
                  :value="dateFormat(user.approved_date)"
                  label="Approved Date"
                  type="text"
                  dense
                  :loading="loading"
                  :disabled="true"
                ></v-text-field>
                <!-- <v-select
                  v-model="user.bootcamp_status"
                  :items="['No', 'Registered', 'Attended']"
                  label="Bootcamp Status"
                  dense
                  :loading="loading"
                  :disabled="loading"
                ></v-select> -->
              </v-col>

              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="user.mail_credit"
                  label="Mail Credit (x1000)"
                  dense
                  :loading="loading"
                  :disabled="loading"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-menu
                  v-model="menu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      dense
                      :value="formatDate(user.software_expiry)"
                      label="Date"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      :loading="loading"
                      :disabled="loading"
                    >
                    </v-text-field>
                  </template>
                  <v-date-picker
                    v-model="user.software_expiry"
                    @input="menu = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" sm="6">
                <v-select
                  v-model="user.source"
                  :items="source_items"
                  label="Source"
                  dense
                  :loading="loading"
                  :disabled="loading"
                ></v-select>
              </v-col>
              <v-col cols="12" sm="6"> </v-col>

              <v-col cols="12" sm="3">
                <v-switch
                  :loading="loading"
                  :disabled="true"
                  v-model="user.seminar_control_training"
                  label="Seminar Control"
                ></v-switch>
              </v-col>
              <v-col cols="12" sm="3">
                <v-tooltip bottom v-if="!user.seminar_control_training">
                  <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on">
                      <v-switch
                        :loading="loading"
                        :disabled="true"
                        v-model="user.approved"
                        label="Licensed Agent"
                      >
                      </v-switch>
                    </span>
                  </template>
                  <span
                    >Must buy Seminar Control prior to becoming an agent.</span
                  >
                </v-tooltip>
                <v-switch
                  v-else
                  :loading="loading"
                  :disabled="loading"
                  v-model="user.approved"
                  label="Licensed Agent"
                >
                </v-switch>
              </v-col>
              <v-col cols="12" sm="3">
                <v-switch
                  :loading="loading"
                  :disabled="loading"
                  v-model="user.hidden"
                  label="Internal"
                ></v-switch>
              </v-col>
              <v-col cols="12" sm="3">
                <v-switch
                  :loading="loading"
                  :disabled="loading"
                  v-model="user.has_ppt"
                  label="Has PPT"
                ></v-switch>
              </v-col>
            </v-row>

            <v-row>
              <v-dialog v-model="dialog" width="500">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="error"
                    dark
                    v-bind="attrs"
                    v-on="on"
                    class="btn-fix"
                  >
                    Delete Account
                  </v-btn>
                </template>

                <v-card>
                  <v-card-title> Are you sure? </v-card-title>

                  <v-card-text>
                    Deleting a user can not be undone. Any saved reports,
                    seminars, households they have will be deleted too.
                  </v-card-text>

                  <v-divider></v-divider>

                  <v-card-actions>
                    <v-btn color="primary" text @click="dialog = false">
                      Cancel
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="error" @click="deleteUser"> I accept </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>

              <v-spacer></v-spacer>

              <v-btn
                :loading="loading"
                :disabled="loading"
                class="mr-3 mb-3"
                @click="updateUser"
                color="primary"
              >
                Update
              </v-btn>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
      <user-card-notes v-if="tab == 1" :user="user"></user-card-notes>
      <!-- <proposal-table v-if="tab == 2" :user_id="user_id"></proposal-table> -->
      <case-table v-if="tab == 2" :user_id="user_id"></case-table>
      <user-card-carriers
        v-if="tab == 3 && user"
        :user_id="user.id"
      ></user-card-carriers>
      <user-card-seminar
        v-if="tab == 4 && user"
        :user_id="user.id"
      ></user-card-seminar>
    </v-card-text>
  </v-card>
</template>

<style scoped>
.btn-fix:focus::before {
  opacity: 0 !important;
}
</style>

<script>
// import ProposalTable from "../Shared/ProposalTable.vue";
import CaseTable from "../Shared/CaseTable.vue";
import hasuraService from "@/services/hasura.service.js";
import UserCardSeminar from "./UserCardSeminar.vue";
import UserCardCarriers from "./UserCardCarriers.vue";
import UserCardNotes from "./UserCardNotes.vue";
import dayjs from "dayjs";

export default {
  name: "AdminUserCard",
  mixins: [hasuraService],
  props: {
    user_id: {
      type: Number,
      required: true,
    },
  },
  components: {
    // ProposalTable,
    CaseTable,
    UserCardSeminar,
    UserCardCarriers,
    UserCardNotes,
  },
  async mounted() {
    this.setup_user();
    this.get_marketers().then((marketers) => {
      this.marketers = marketers;
    });
  },
  watch: {
    user_id() {
      this.setup_user();
    },
  },

  methods: {
    formatDate(date) {
      if (date == null) {
        return "";
      }
      return dayjs(date).format("MM/DD/YYYY");
    },
    setup_user: async function () {
      this.loading = true;
      this.user = await this.get_user_by_pk(this.user_id);
      this.user_previous_state = JSON.parse(JSON.stringify(this.user));
      this.loading = false;
    },
    deleteUser: async function () {
      this.loading = true;
      this.dialog = false;
      await this.delete_user_by_pk(this.user_id);
      this.$store.commit("set_snackbar", "Account Deleted.");
      this.loading = false;
    },
    updateUser: async function () {
      this.loading = true;
      if (this.user.approved != this.user_previous_state.approved) {
        console.log("Changing all_in");
        if (this.user.approved) {
          this.user.approved_date = new Date();
          this.sendTemplateEmail(
            this.user.email,
            this.user.first_name + " " + this.user.last_name,
            "agent-approval",
            {
              name: this.user.first_name + " " + this.user.last_name,
            }
          );
        } else {
          this.user.approved_date = null;
        }
      }
      await this.update_user_by_pk(this.user_id, this.user);
      this.$store.commit("set_snackbar", "Saved!");
      this.loading = false;
    },
  },

  data() {
    return {
      tab: 0,
      menu: false,
      valid: true,
      dialog: false,
      marketers: [],
      loading: false,
      user: {},
      user_previous_state: {},
      tab_items: ["Agent Details", "Notes", "Cases", "Carriers", "Seminars"],
      source_items: [
        {
          text: "Unknown",
          value: "unknown",
        },
        {
          text: "ARN",
          value: "arn",
        },
        {
          text: "LinkedIn",
          value: "LinkedIn",
        },
        {
          text: "Email",
          value: "email",
        },
        {
          text: "ECA",
          value: "eca",
        },
        {
          text: "Other",
          value: "other",
        },
        {
          text: "Lead Marvels",
          value: "lead_marvels",
        },
      ],
    };
  },
};
</script>
