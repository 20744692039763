var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"elevation-1 my-3",attrs:{"headers":_vm.headers,"items":_vm.clients,"loading":_vm.loading,"hide-default-footer":"","disable-pagination":"","sort-by":"created_at","sort-desc":""},on:{"click:row":_vm.handleClick},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Cases")]),_c('v-spacer'),_c('instructions-dialog',{attrs:{"title":"How to Set Up a New Case","videoURI":"e962c53f93e453105d60efdd5cdb9a73"}}),_c('v-dialog',{attrs:{"max-width":"600px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","dark":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus")]),_vm._v(" New Case ")],1)]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v(_vm._s(_vm.formTitle))])]),_c('v-card-text',[_c('v-container',[_c('v-form',{ref:"form",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-text-field',{attrs:{"label":"Client Name","rules":_vm.req},model:{value:(_vm.editedItem.name),callback:function ($$v) {_vm.$set(_vm.editedItem, "name", $$v)},expression:"editedItem.name"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-radio-group',{attrs:{"row":!_vm.$vuetify.breakpoint.xsOnly},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',[_vm._v("Gender")])]},proxy:true}]),model:{value:(_vm.editedItem.gender),callback:function ($$v) {_vm.$set(_vm.editedItem, "gender", $$v)},expression:"editedItem.gender"}},_vm._l(([
                          { view: 'Male', value: 'male' },
                          { view: 'Female', value: 'female' } ]),function(g){return _c('v-radio',{key:g.value,attrs:{"dense":"","label":g.view,"value":g.value}})}),1)],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-select',{attrs:{"items":_vm.all_states,"label":"State","item-value":"abbreviation","item-text":"name","rules":_vm.req},model:{value:(_vm.editedItem.state),callback:function ($$v) {_vm.$set(_vm.editedItem, "state", $$v)},expression:"editedItem.state"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-text-field',{attrs:{"validate-on-blur":"","label":"Current Age","type":"number","rules":[
                        function (v) { return !!v || 'Field is required'; },
                        function (v) { return (v && v >= 40) || 'Age must be greater than 40'; },
                        function (v) { return (v && v < 99) || 'Age must be less than 99'; } ]},model:{value:(_vm.editedItem.age),callback:function ($$v) {_vm.$set(_vm.editedItem, "age", $$v)},expression:"editedItem.age"}})],1)],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.close}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"color":"primary","loading":_vm.loading},on:{"click":_vm.save}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-check")]),_vm._v(" Save ")],1)],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5"},[_vm._v(" Are you sure you want to delete this item? ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.closeDelete}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.deleteItemConfirm}},[_vm._v(" OK ")]),_c('v-spacer')],1)],1)],1)],1)]},proxy:true},{key:"item.gender",fn:function(ref){
                      var item = ref.item;
return [_c('span',{staticClass:"titlecase"},[_vm._v(_vm._s(item.gender))])]}},{key:"item.created_at",fn:function(ref){
                      var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.dateFormat(item.created_at)))])]}},{key:"item.actions",fn:function(ref){
                      var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){$event.stopPropagation();return _vm.editItem(item)}}},[_vm._v(" mdi-pencil ")]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){$event.stopPropagation();return _vm.deleteItem(item)}}},[_vm._v(" mdi-delete ")])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }